<template>
    <sidebar-menu
        :menu="menu"
        :collapsed="collapsed"
        @toggle-collapse="onToggleCollapse"
    />
</template>

<script>
export default {
    name: 'TheSidebar',
    data() {
        return {
            collapsed: true,
            menu: [
                {
                    header: true,
                    title: '',
                    hiddenOnCollapse: true,
                },
                {
                    title: 'Inicio',
                    href: '/a',
                    icon: 'mdi mdi-home mdi-24px',
                },
                {
                    title: 'Trabajadores',
                    icon: 'mdi mdi-account-multiple mdi-24px',
                    child: [
                        {
                            href: '/a/trabajadores',
                            title: 'Buscar',
                        },
                        {
                            href: '/a/trabajadores/carga/individual',
                            title: 'Carga Individual',
                        },
                        {
                            href: '/a/trabajadores/carga/masiva',
                            title: 'Carga Masiva',
                        },
                    ],
                },
                {
                    title: 'Documentos',
                    icon: 'mdi mdi-text-box mdi-24px',
                    child: [
                        {
                            href: '/a/documentos/buscador',
                            title: 'Buscar',
                        },
                        {
                            href: '/a/documentos/tipos-documentos',
                            title: 'Tipos Documentos',
                        },
                        {
                            title: 'Publicación',
                            child: [
                                {
                                    href: '/a/documentos/publicacion/masiva',
                                    title: 'Masiva',
                                },
                                {
                                    href:
                                        '/a/documentos/publicacion/individual',
                                    title: 'Individual',
                                },
                                {
                                    href: '/a/documentos/publicacion/gestion',
                                    title: 'Gestionar',
                                },
                            ],
                        },
                    ],
                },
                {
                    title: 'Configuración',
                    icon: 'mdi mdi-cog mdi-24px',
                    child: [
                        {
                            href: '/a/empresa/datos',
                            title: 'Información',
                        },
                        {
                            href: '/a/empresa/procesos',
                            title: 'Procesos'
                        }
                    ],
                },
                {
                    title: 'Reportes',
                    icon: 'mdi mdi-chart-bar mdi-24px',
                    href: '/a/reportes',
                },
                /* {
                    title: 'Integración',
                    icon: 'mdi mdi-cloud-sync mdi-24px',
                    href: '/a/integracion',
                }, */
            ],
        };
    },
    computed: {
        sidebar: {
            get() {
                return this.$store.state.ui.sidebar;
            },
            set(value) {
                this.$store.dispatch('ui/toggleSidebar', value);
            },
        },
        perfil() {
            return this.$store.state.me.perfil;
        },
        fotoEmpresa() {
            return this.$store.state.me.perfil.trabajador.empresa.ruta_imagen
                ? `${this.base_url}/${this.$store.state.me.perfil.trabajador.empresa.ruta_imagen}`
                : null;
        },
    },
    mounted() {
        this.onToggleCollapse(this.collapsed);
    },
    methods: {
        onToggleCollapse(collapsed) {
            const elements = document.getElementsByClassName('layout');
            if (collapsed) {
                elements[0].classList.add('collapsed');
            } else {
                elements[0].classList.remove('collapsed');
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>
