<template>
    <section class="layout">
        <the-sidebar />
        <the-navbar />
        <div class="layout--content">
            <transition name="fade">
                <router-view></router-view>
            </transition>
        </div>
    </section>
</template>

<script>
import TheSidebar from '@/components/shared/Layout/TheSidebar.vue';
import TheNavbar from '@/components/shared/Layout/TheNavbar.vue';
import PageLoader from '@/components/shared/PageLoader.vue';

export default {
    components: { TheSidebar, TheNavbar, PageLoader },
    name: 'Admin',
    metaInfo: {
        title: 'Inicio',
    },
    data() {
        return {
            displayLoader: false,
        };
    },
    async mounted() {
        /* if (!this.currentToken) {
            await this.$router.push('/login');
        } else {
            this.displayLoader = true;
            try {
                const result = await this.$store.dispatch('me/get');

                switch (result.perfil.descripcion) {
                    case 'Empleado':
                        await this.$router.push('/u');
                        break;
                    case 'Recursos Humanos':
                        break;
                    default:
                        await this.$router.push('/account');
                        break;
                }
            } catch (e) {
                await this.$store.dispatch('auth/logout');
                await this.$router.push('/login');
            } finally {
                this.displayLoader = false;
            }
        } */
    },
    computed: {
        currentToken() {
            return this.$store.state.auth.token;
        },
        perfil() {
            return this.$store.state.me.perfil;
        },
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
    },
};
</script>

<style lang="scss" scoped>
.layout {
    padding-left: 350px;
    -webkit-transition: 0.3s ease;
    transition: 0.3s ease;
}

.layout.collapsed {
    padding-left: 50px;
}

.layout--content {
    min-height: calc(100vh - 3.25vh);
    padding: 25px 50px;
}

@media screen and (max-width: 768px) {
    .layout--content {
        padding: 10px 15px;
    }
}
</style>
